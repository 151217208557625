import React, { useEffect, useRef, useState } from 'react'
import PizzeriaFilter from '../../components/ordersPage/ordersTable/filters/PizzeriaFilter'
import { Grid } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { ICustomer } from 'dto/users.dto'
import UsersService from 'dataStore/service/users.service'
import { useSelector } from 'react-redux'
import { RootState } from 'dataStore/state'
import Button from '@mui/material/Button'
import { useDownloadExcel } from 'react-export-table-to-excel'

const Customers = () => {
  const [customers, setCustomers] = useState<ICustomer[]>([])
  const { filter } = useSelector((state: RootState) => state.orders)
  const { pizzerias } = useSelector((state: RootState) => state.pizzerias)
  const tableRef = useRef(null)
  const pizzeria = pizzerias?.find(el => el?.id === filter?.pizzeria_id)

  useEffect(() => {
    if (filter?.pizzeria_id) {
      UsersService.getUsersByPizzeriaId(filter.pizzeria_id)
        .then(res => setCustomers(res?.data ?? []))
        .catch((error: any) => {
          console.log({ error })
          setCustomers([])
        })
    } else {
      setCustomers([])
    }
  }, [filter?.pizzeria_id])

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: pizzeria?.address?.city ? pizzeria?.address?.city : 'Users',
    sheet: 'Users',
  })

  return (
    <div>
      <Grid container spacing={3} sx={{ mb: '25px' }}>
        <Grid item xs={12} sm={3} md={3}>
          <PizzeriaFilter />
          <Button
            variant='contained'
            fullWidth
            sx={{ mt: 2 }}
            disabled={!customers?.length}
            onClick={onDownload}
          >
            Скачать
          </Button>
        </Grid>
        <Grid item xs={12} sm={9} md={9}>
          <TableContainer component={Paper}>
            <Table size='small' aria-label='a dense table' ref={tableRef}>
              <TableHead>
                <TableRow>
                  <TableCell>N#</TableCell>
                  <TableCell align='right'>id</TableCell>
                  <TableCell align='right'>Имя</TableCell>
                  <TableCell align='right'>Тел.</TableCell>
                  <TableCell align='right'>email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customers?.map((row, i) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component='th' scope='row'>
                      {i + 1}
                    </TableCell>
                    <TableCell align='right'>{row?.id}</TableCell>
                    <TableCell align='right'>{row?.first_name}</TableCell>
                    <TableCell align='right'>{row?.phone}</TableCell>
                    <TableCell align='right'>{row?.email}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  )
}

export default Customers
