export const toSlug = (title?: string | null): string | null | undefined => {
  if (!title) return title
  const a: any = {
    Ё: 'YO',
    Й: 'I',
    Ц: 'TS',
    У: 'U',
    К: 'K',
    Е: 'E',
    Н: 'N',
    Г: 'G',
    Ш: 'SH',
    Щ: 'SCH',
    З: 'Z',
    Х: 'H',
    Ъ: '_',
    ё: 'yo',
    й: 'i',
    ц: 'ts',
    у: 'u',
    к: 'k',
    е: 'e',
    н: 'n',
    г: 'g',
    ш: 'sh',
    щ: 'sch',
    з: 'z',
    х: 'h',
    ъ: '_',
    Ф: 'F',
    Ы: 'I',
    В: 'V',
    А: 'А',
    П: 'P',
    Р: 'R',
    О: 'O',
    Л: 'L',
    Д: 'D',
    Ж: 'ZH',
    Э: 'E',
    ф: 'f',
    ы: 'i',
    в: 'v',
    а: 'a',
    п: 'p',
    р: 'r',
    о: 'o',
    л: 'l',
    д: 'd',
    ж: 'zh',
    э: 'e',
    Я: 'Ya',
    Ч: 'CH',
    С: 'S',
    М: 'M',
    И: 'I',
    Т: 'T',
    Ь: '_',
    Б: 'B',
    Ю: 'YU',
    я: 'ya',
    ч: 'ch',
    с: 's',
    м: 'm',
    и: 'i',
    т: 't',
    ь: '_',
    б: 'b',
    ю: 'yu',
    "'": '_',
    '"': '_',
    '?': '_',
    ',': '_',
    '#': '_',
    $: '_',
    ';': '_',
    ':': '_',
    '/': '_',
    '\\': '_',
    '@': '_',
  }

  return title
    ?.toLocaleLowerCase()
    .split('')
    .map(function (char) {
      return a[char] || char
    })
    .join('')
    .replace(/\s+/g, '_')
}
