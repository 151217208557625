import { FC } from 'react'
import { Grid } from '@mui/material'
import PizzeriaFilter from './PizzeriaFilter'
import OrderTypeFilter from './OrderTypeFilter';
import PaymentTypeFilter from './PaymentTypeFilter';
import PaymentStatusFilter from './PaymentStatusFilter';

const Filters: FC = () => {
	return (
		<>
			<Grid container spacing={3} sx={{ mb: '25px' }}>
				<Grid item xs={12} sm={6} md={3}>
					<PizzeriaFilter />
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<OrderTypeFilter />
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<PaymentTypeFilter />
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<PaymentStatusFilter />
				</Grid>
			</Grid>
		</>
	)
}

export default Filters