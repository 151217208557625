/* import styled from 'styled-components'; */

const colors = {
  primary: '#1976d2',
  primaryHover: '#115293',
  secondary: '#6c757d',
  secondaryHover: '#5a6268',
  success: '#28a745',
  successHover: '#218838',
  steelGray: '#edeef0b8',
  info: '#17a2b8',
  infoHover: '#138496',
  danger: '#dc004e',
  dangerHover: '#9a0036',
  warning: '#ffc107',
  warningHover: '#e0a800',
  dark: '#343a40',
  darkHover: '#23272b',
  default: '#e0e0e0',
  defaultHover: '#d5d5d5',
  white: '#fff',
  black: '#000',
  light: '#f8f9fa',
  lightGray: '#818c99',
  link: '#2a5885',
  disable: 'opacity: .65;',
  background: '#f0f2f5',
  cardHeader: '#e5e9f1',
  red: '#FC5C65',
	red_active: '#EB3B5A',
	opacity_red: '#fc5c6580',
  orange: '#FD9644',
	orange_active: '#FA8231',
	opacity_orange:'#fd964470',
  yellow: '#FED330',
	yellow_active: '#F7B731',
	opacity_yellow: '#f2ff0070',
  green: '#26DE81',
	green_active: '#20BF6B',
	opacity_green: '#26de8170',
  light_green: '#2BCBBA',
  light_green_active: '#10B9B1',
  light_blue: '#45AAF2',
	light_blue_active: '#2D98DA',
	opacity_light_blue: '#00c5ff70',
  blue: '#4B7BEC',
  blue_active: '#3867D6',
  purple: '#A65EEA',
	purple_active: '#8854D0',
	opacity_purple: '#ff007b70',
  light_gray: '#D1D8E0',
  light_gray_active: '#A5B1C2',
  gray: '#778CA3',
	gray_active: '#4B6584',
	opacity_gray: '#d1d8e070',
}

export default colors
