import {FC, useState} from 'react'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import EditIcon from '@mui/icons-material/Edit'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import IconButton from '@mui/material/IconButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';

import { useActions } from 'hooks/useActions'

import { useNavigate } from 'react-router'

const ITEM_HEIGHT = 48


const UserMenu: FC = () => {
	const navigate = useNavigate()
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const [selectedIndex, setSelectedIndex] = useState<number>(-1)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
	}

	const handleListItemClick = (index: number, route: string = ''): void => {
    setSelectedIndex(index)
    handleClose()

    if (route && route !== '') {
      navigate(route)
    }
	}

	const handleLogout = (): void => {
    localStorage.removeItem('token')
    navigate('/auth')
  }
	return (
		<div>
      <IconButton
        aria-label='more'
        id='long-button'
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            /* width: '20ch', */
            borderRadius: '15px',
            padding: '8px',
          },
        }}
      >
        <MenuItem
          onClick={event => handleListItemClick(0, '/settings/')}
					selected={selectedIndex === 0}
          sx={{
            borderRadius: '6px',
          }}
        >
          <ListItemIcon>
            <SettingsOutlinedIcon />
          </ListItemIcon>
          <ListItemText>Настройки</ListItemText>
				</MenuItem>

        <MenuItem
          selected={selectedIndex === 1}
					onClick={event => handleListItemClick(1, '/profile/')}
          sx={{
            borderRadius: '6px',
          }}
        >
          <ListItemIcon>
            <AccountCircleOutlinedIcon />
          </ListItemIcon>
          <ListItemText>Профиль</ListItemText>
				</MenuItem>

        <MenuItem
          selected={selectedIndex === 2}
					onClick={handleLogout}
          sx={{
            borderRadius: '6px',
            color: 'rgb(255, 72, 66)',
          }}
        >
          <ListItemIcon>
            <PowerSettingsNewOutlinedIcon
              sx={{
                color: 'rgb(255, 72, 66)',
              }}
            />
          </ListItemIcon>
          <ListItemText>Выход</ListItemText>
        </MenuItem>
      </Menu>
    </div>
	)
}

export default UserMenu