import { FC, useState, useRef, useEffect } from 'react'
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { useSelector } from 'react-redux';
import { RootState } from 'dataStore/state';
import { useActions } from 'hooks/useActions';




// interface IProps {
// 	setState: Function
// }

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: '#6c757d',
  backgroundColor: '#fff',
  '&:hover': {
    backgroundColor: '#ffffff',
  },
}));

const OrderTypeFilter: FC = () => {
	const { statuses } = useSelector((state: RootState) => state.orders)
	const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
	const [selectedIndex, setSelectedIndex] = useState<number>(0);
	const [options, setOptions] = useState([{ id: 0, value: 'Все статусы' }])

	const { getAllOrderStatus, setFilterStatusId, setShowOrdersFilter } = useActions()
	useEffect(() => {
		if (!statuses?.length) {
			getAllOrderStatus()
		}
		setOptions( [ {id: 0, value: 'Все статусы'}, ...statuses?.map(el=> ({id: el.id!, value: `${el?.description}`}))])
	}, [statuses])

  /* const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex].id}`);
  }; */

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
	) => {
    setSelectedIndex(index!);
		setOpen(false);
		if (index === 0) {
			setFilterStatusId(null)
		} else {
			setFilterStatusId(index)
		}
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };
	return (
		<>
			<ButtonGroup variant="contained" ref={anchorRef} aria-label="split button" sx={{borderRadius: 28, width: '100%', boxShadow: 3}}>
        <ColorButton onClick={handleToggle} sx={ { borderRadius: 28, overflow: 'hidden', whiteSpace: 'nowrap' } } fullWidth>{options?.find(el=> el?.id === selectedIndex)?.value}</ColorButton>
        <ColorButton
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
					onClick={handleToggle}
					sx={ { borderRadius: 28 } }
        >
          <ArrowDropDownIcon />
        </ColorButton>
      </ButtonGroup>
      <Popper
        sx={{
					zIndex: 1,
					borderRadius: 28
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper sx={{borderRadius: 3, overflow: 'hidden'}}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.id}
                      /* disabled={index === 2} */
                      selected={option.id === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, option.id!)}
                    >
                      {option.value}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
		</>
	)
}

export default OrderTypeFilter