import dashboard from './dashboard'
/* import pages from './pages'; */
import products from './products'
import other from './other'

// ==============================|| MENU ITEMS ||============================== //

const menuItems = [dashboard, /* pages, */ products, other]

export default menuItems
