import OrdersTable from 'components/ordersPage'
import { RootState } from 'dataStore/state'
import { IUser } from 'dto/users.dto'
import { useActions } from 'hooks/useActions'
import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const Statistics: FC = () => {
	const { getAllOrders, getStatistic } = useActions()
	const { pagination, filter } = useSelector((state: RootState) => state.orders)

  useEffect(() => {
		getAllOrders(pagination)
		getStatistic(filter.pizzeria_id)
	}, [pagination])

  useEffect(() => {
		getStatistic(filter.pizzeria_id)
	}, [filter.pizzeria_id])

	useEffect(() => {
    const timer = setTimeout(() => {
			getAllOrders(pagination)
			getStatistic(filter.pizzeria_id)
    }, 120000)
    return () => {
      clearTimeout(timer)
    }
  })

  return (
    <div>
      <OrdersTable />
    </div>
  )
}

export default Statistics
